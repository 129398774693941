import {
    ADD_NOTIFICATION,
    DELETE_NOTIFICATION,
} from "../../constants/constants";

const initialState: any = [];

type Action = {
    type: string;
    data: Notification | string;
};

const toast = (state = initialState, action: Action) => {
    switch (action.type) {
        case ADD_NOTIFICATION: {
            return [...state, action.data];
        }
        case DELETE_NOTIFICATION: {
            return state.filter(
                (notification: any) => notification.id !== action.data
            );
        }
        default:
            return state;
    }
};

export default toast;
