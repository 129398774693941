export default function transactionSkeletonLoader() {

    const shapes = new Array(9).fill(1)
    return (
        <div className="w-full mt-16 ml-12 absolute flex flex-col items-center justify-center right-0 bg-white ">
            <div className=" flex flex-col justify-center items-center w-full h-full animate-pulse shadow-md rounded p-2 space-y-6">

                {shapes.map((_, index) => (
                    <div className="bg-gray-200 p-4 rounded w-full" key={index}></div>
                ))}

            </div>
        </div>
    );
}