import { useEffect } from "react";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import Sidebar from "../components/sidebar/sidebar";
import { useAppDispatch } from "../store/hooks";
import { getRole } from "../services/role";
import { LOCAL_STORAGE } from "../utils/storage";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import useLogoutUser from "../utils/logout";


import ToastContainer from "../components/notification/toastContainer";
import { USER_TOKEN } from "../constants/storageKeys";
import { LOGIN } from "../constants/routes";

interface LayoutProps {
    children?: React.ReactNode;
}
const Layout = ({ children }: LayoutProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { logWithError } = useLogoutUser()

    const rolesError = useAppSelector((state) => state.role.roleError);

    useEffect(() => {
        dispatch(getRole());
        
        if (!LOCAL_STORAGE.get(USER_TOKEN) || LOCAL_STORAGE.get(USER_TOKEN) === null) {
            navigate(LOGIN);
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if (rolesError) {
            logWithError()
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolesError]); 

    return (
        <div className="bg-[#F4F2F2]">
            <Header />
            <div className="grid grid-cols-6 gap-x-16 pr-8">
                <Sidebar />
                <div className="mt-14 mb-8 col-span-5 bg-white min-h-[800px]">
                    {children}
                </div>
            </div>

            <Footer />
            <ToastContainer />
        </div>
    );
};
export default Layout;
