import { clearStorage } from "./storage";
import { useAppDispatch } from "../store/hooks";
import { addNotification } from "./notification";
import { NotificationStatus } from "../types";
import { LOGIN } from "../constants/routes";

const useLogoutUser = () => {
    const dispatch = useAppDispatch();
    const logout = () => {
        clearStorage();
        window.location.href = LOGIN;
    };

    const logWithError = () => {
        dispatch(
            addNotification({
                title: "Session Expired",
                type: NotificationStatus.ERROR,
                message: "Login again to continue",
            })
        );
        setTimeout(() => {
            logout();
        }, 3000);
    };

    return { logWithError, logout };
};

export default useLogoutUser;
