import { LOCAL_STORAGE } from "./storage";
import { BASE_URL } from "./constant";
import * as KEYS from "../constants/storageKeys";

type FetchProperties = {
    method: string,
    headers: {
        'Content-Type': string,
        'Authorization'?: string
    },
    mode?: RequestMode,
    body?: string
}

export const BuildApiRequest = (url: string, method: string, data?: any, protectedURL?: boolean) => {
    const fetchProperties: FetchProperties = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
        mode: "cors",
    };

    if (method !== "GET" && data) {
        fetchProperties.body = JSON.stringify(data);
    }

    const token = LOCAL_STORAGE.get(KEYS.USER_TOKEN);
    if (protectedURL && token) {
        fetchProperties.headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${JSON.parse(token)}`,
        }
    }

    return fetch(`${BASE_URL}${url}`, fetchProperties);
}