export default function FiltersSkeletonLoader() {
    const shapes = new Array(2).fill(2);

    return (
        <div className=" w-[23%] h-[35vh] bg-white animate-pulse rounded">
            <div className="flex flex-col w-full h-full shadow-md p-4 justify-around">
                {shapes.map((_, index) => (
                    <div key={index} className="space-y-2">
                        <div className="w-28 rounded-md bg-gray-200 p-3 my-3"></div>
                        <div className="p-4 w-full bg-gray-200 rounded-md"></div>
                        <div className="p-4 w-[200px] bg-gray-200 rounded-md"></div>
                    </div>
                ))}
            </div>
        </div>
    );
}
