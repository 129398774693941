import moment from 'moment';

export const GetCurrentYear = () => {
    return new Date().getFullYear();
}

export const GetTodayDate = (): string => {
    return moment().format("YYYY-MM-DD");
}

export const GetCurrentWeek = (): string => {
    return moment().week().toString();
}

export const GetCurrentMonth = (): string => {
    return moment().month().toString();
}

export const FormatFullDate = (date: string): string => {
    return moment(date).format("MMMM Do YYYY, h:mm:ss a");
}