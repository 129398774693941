import { TransactionStatus } from "../../types";

export interface GenerateDataSetReturnType {
	label: string;
	data: any[];
	borderColor: string;
	fill: boolean;
	tention?: number;
}
 
export const GenerateDataSets = (
	status: TransactionStatus,
	args: any
): GenerateDataSetReturnType[] => {
	switch (status.toLowerCase()) {
		case TransactionStatus.SUCCESS:
			return [
				{
					label: TransactionStatus.SUCCESS,
					data: Object.values(args[TransactionStatus.SUCCESS]),
					borderColor: "#47EE76",
					fill: false,
					tention: 0.4,
				},
			];
		case TransactionStatus.FAILED:
			return [
				{
					label: TransactionStatus.FAILED,
					data: Object.values(args[TransactionStatus.FAILED]),
					borderColor: "#F47B7B",
					fill: false,
				},
			];
		case TransactionStatus.PENDING:
			return [
				{
					label: TransactionStatus.PENDING,
					data: Object.values(args[TransactionStatus.PENDING]),
					borderColor: "#F5D00C",
					fill: false,
				},
			];

		case TransactionStatus.NOT_STARTED:
			return [
				{
					label: TransactionStatus.NOT_STARTED,
					data: Object.values(args[TransactionStatus.NOT_STARTED]),
					borderColor: "#3A3A3A",
					fill: false,
				},
			];
		default:
			return [
				{
					label: TransactionStatus.SUCCESS,
					data: Object.values(args[TransactionStatus.SUCCESS]),
					borderColor: "#47EE76",
					fill: false,
				},
				{
					label: TransactionStatus.FAILED,
					data: Object.values(args[TransactionStatus.FAILED]),
					borderColor: "#F47B7B",
					fill: false,
				},
				{
					label: TransactionStatus.PENDING,
					data: Object.values(args[TransactionStatus.PENDING]),
					borderColor: "#3A3A3A",
					fill: false,
				},
				{
					label: TransactionStatus.FAILED,
					data: Object.values(args[TransactionStatus.NOT_STARTED]),
					borderColor: "#3A3A3A",
					fill: true,
				},
			];
	}
};

export const generateLebals = (transactions: any) => {
	if (!transactions.error) {
		let keys = Object.keys(transactions[TransactionStatus.SUCCESS]);
		return keys.map((elem) => {
			return elem.slice(1, elem.length - 1);
		});
	}
};
