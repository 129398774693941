import { TRANSACTION_API_FAIL, GET_TRANSACTION, CLEAR_TRANSACTIONS } from "../constants/constants";
import { BuildApiRequest } from "../utils/helper";


export const getRolePromise = async ({ start, limit }: { start: number, limit: number }) => {
    try {
        const res = await BuildApiRequest(`/transactions?zone=Africa/Douala&start=${start}&limit=${limit}`, "GET", null, true);

        const data = await res.json();

        return data;
    }
    catch (error: any) {
        throw new Error(error);
    }

}

export const getTransaction =
    ({ start, limit }: { start: number, limit: number }) => (dispatch: any) => {
        return getRolePromise({ start, limit })
            .then((resp) =>
                dispatch({
                    type: GET_TRANSACTION,
                    data: resp,
                })
            )
            .catch((error) =>
                dispatch({
                    type: TRANSACTION_API_FAIL,
                    data: error.message,
                })
            );
    };

export const clearTransactions = () => (dispatch: any) => {
    dispatch({
        type: CLEAR_TRANSACTIONS,
        data: null,
    });
}
