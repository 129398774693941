import { useAppSelector } from "../../store/hooks";
import MessagegModal from "./messageModal";
import { NotificationData } from "../../types";

const ToastContainer = () => {
    const notifications = useAppSelector((state) => state.toastNotifications);

    return (
        <div className=" transition-transform transform duration-500 fixed right-0 top-24  flex flex-col gap-2  z-50">
            {notifications.map((notification: NotificationData) => (
                <MessagegModal
                    key={notification.id}
                    notification={notification}
                />
            ))}
        </div>
    );
};
export default ToastContainer;
