import { useState } from "react";
import { emailDataList } from './EmailData';
import { Paginator } from "../../components/paginator";
import { IoMdArrowRoundBack } from "@react-icons/all-files/io/IoMdArrowRoundBack";

const Emails = () => {
    const range = 3;
    const [startIndex, setStartIdex] = useState<number>(1);
    const [endIndex, setEndIndex] = useState(range);

    const [emailList, setShowEmailList] = useState(true);
    const [emailData, setViewEmailData] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState<any>();

    const keysArray = Object.keys(emailDataList);

    const emailcount = keysArray.length;

    const handleNext = () => {
        if (endIndex >= emailcount) {
            // fetch new data here
            return;
        }
        setStartIdex((prev) => prev + range);
        setEndIndex((prev) => prev + range);
    };

    const handlePrev = () => {
        if (startIndex === 1) return;
        setStartIdex((prev) => prev - range);
        setEndIndex((prev) => prev - range);
    };

    return (
        <div className="">

            {emailList ?
                <>
                    <div className="flex justify-end m-5">
                        <Paginator
                            startIndex={startIndex}
                            endIndex={endIndex}
                            onClickNext={handleNext}
                            onClickPrev={handlePrev}
                            totalCount={emailcount}
                        />
                    </div>
                    {emailDataList.slice(startIndex - 1, endIndex).map((emaildata, index) => (
                        <div data-index={index} key={index} className="bg-gray-50 p-5 mx-16 mb-5 rounded-lg cursor-pointer hover:bg-purple-50"
                            onClick={() => {
                                setShowEmailList(false);
                                setViewEmailData(true);
                                setSelectedEmail(emaildata);
                            }}>
                            <div className="flex flex-row flex justify-between">
                                <div className="flex">
                                    <div className="flex items-center justify-center h-10 w-10 rounded-full bg-purple-400">
                                        <span className="text-white font-bold text-sm">
                                            DA
                                        </span>
                                    </div>
                                    <p className="ml-3">{emaildata.senderName}</p>
                                </div>
                                <p className="mr-5">{emaildata.date}</p>
                            </div>
                            <div className="ml-12 pl-1 mr-40">
                                <p className="font-semibold text-sm mb-2">{emaildata.subject}</p>
                                <p className="text-xs truncate">{emaildata.body}</p>
                            </div>
                        </div>
                    ))}
                </> : null
            }

            {
                emailData ? (
                    <>
                        <div className="bg-gray-50 mt-10 mb-10 mx-32 min-h-96">
                            <div onClick={() => {
                                setShowEmailList(true);
                                setViewEmailData(false);
                            }}
                                className="pt-8 pl-5 cursor-pointer"> <IoMdArrowRoundBack color="gray" size={30} /> </div>
                            <div className="flex items-start justify-between px-5 pt-3 ml-8">
                                <button
                                    className="bg-blue-600 text-white px-6 py-3 rounded mr-5 mb-1 hover:bg-blue-800"
                                    type="button" >Resolved</button>
                                <button
                                    className="bg-red-400 text-white px-6 py-3 rounded mr-5 mb-1 hover:bg-red-600"
                                    type="button">Flag</button>
                                <button
                                    className="bg-gray-400 text-white px-6 py-3 rounded mr-5 mb-1 hover:bg-gray-600"
                                    type="button" >Assign</button>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => setViewEmailData(false)}
                                >
                                </button>
                            </div>
                            <div className="relative p-6 flex-auto">
                                <p className="my-4 mx-8 font-medium text-base">{selectedEmail.senderName}</p>
                                <p className="my-4 mx-8 font-semibold text-xl">{selectedEmail.subject}</p>
                                <p className="my-4 ml-8 mr-5 text-base">{selectedEmail.body}</p>
                            </div>
                        </div>
                    </>
                ) : null
            }
        </div >
    );
};

export default Emails;
