import { useEffect, useState } from "react";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import { NotificationData, NotificationStatus } from "../../types";
import { useAppDispatch } from "../../store/hooks";
import { deleteNotification } from "../../utils/notification";

type PropTypes = { notification: NotificationData };

const MessagegModal = ({ notification }: PropTypes) => {
    const [showMessage, setShowmessage] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        setTimeout(() => {
            setIsOpen(true);
        }, 100);
    }, []);

    const handleDeleteNotification = (id: string) => {
        setIsOpen(false);
        setTimeout(() => {
            dispatch(deleteNotification(id));
        }, 500);
    };

    return (
        <div
            className={`transform transition-all w-[400px] ease-in-out duration-500  ${
                isOpen
                    ? "translate-x-[0] opacity-100"
                    : "translate-x-[300%] opacity-0"
            } ${
                notification.type === NotificationStatus.SUCCESS
                    ? "bg-[#47EE76]"
                    : "bg-[#F47B7B]"
            } min-h-12   space-y-4 text-white `}
        >
            <div
                onClick={() => setShowmessage((prev) => !prev)}
                className="space-y-4 hover:cursor-pointer  py-3 px-5"
            >
                <h3 className="text-white font-semibold">
                    {notification.title}
                </h3>

                {showMessage && <p className="pb-8">{notification.message}</p>}
            </div>

            <button
                onClick={() => {
                    handleDeleteNotification(notification.id);
                }}
                className="absolute top-[-13px] z-50 right-2 p-2"
            >
                <MdClose size={18} />
            </button>
        </div>
    );
};

export default MessagegModal;
