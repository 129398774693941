import React, { useState } from "react";
import Input from "../input";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import Button from "../button";
import { createNewUser } from "../../services/user";
import { addNotification } from "../../utils/notification";
import { NotificationStatus } from "../../types";

type EditDataProps = {
    data?: any;
};

function isValidEmail(email: string) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email.toLowerCase());
}

export const UserForm = ({ data }: EditDataProps) => {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [role_id, setRole_id] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();
    const roles = useAppSelector((state) => state.role.role);
    const handleCreateUser = () => {
        setLoading(true);
        if (!firstname || !lastname || !email || !username || !role_id) {
            setErrorMessage("all fields are required");
            setLoading(false);
            return;
        }

        if (!isValidEmail(email)) {
            setErrorMessage("Please enter a valid email address");
            setLoading(false);
            return;
        }

        setErrorMessage("");
        const userData = {
            firstname,
            lastname,
            email,
            username,
            role_id,
        };
        dispatch(createNewUser(userData)).then((res: any) => {
            if (res.data.error) {
                dispatch(
                    addNotification({
                        title: "Failed to create user",
                        type: NotificationStatus.ERROR,
                        message: res.data.message,
                    })
                );
            }
            setLoading(false);
        });
    };

    return (
        <div className="bg-white z-50 opacity-100 absolute left-[36%] top-48 pt-8 pb-14 px-16">
            <h3 className="text-lg font-semibold underline-offset-1 underline ">
                User
            </h3>
            <div className="container flex flex-col gap-6 mt-8">
                <div className="flex gap-7 justify-between">
                    <Input
                        title="Firstname"
                        type={""}
                        defaultValue={data?.firstname}
                        onChange={(value) => setFirstname(value)}
                    />
                    <Input
                        title="Lastname"
                        type={""}
                        defaultValue={data?.lastname}
                        onChange={(value) => setLastname(value)}
                    />
                </div>
                <Input
                    title="Username"
                    type={""}
                    defaultValue={data?.username}
                    onChange={(value) => setUsername(value)}
                />
                <Input
                    title="Email"
                    defaultValue={data?.email}
                    type="email"
                    onChange={(value) => setEmail(value)}
                />
                <div className="flex flex-col gap-3">
                    <label htmlFor="" className="font-semibold text-lg">
                        Role
                    </label>
                    <div className="select-dropdown w-fill px-1 border border-[#B9B9B9] rounded-md relative">
                        <select
                            onChange={(e) => setRole_id(e.target.value)}
                            id=""
                            className="h-10 outline-none border-none w-full rounded-md bg-transparent"
                        >
                            <option className="absolute w-full hidden">
                                select a role
                            </option>
                            {roles.length > 0 &&
                                roles?.map(
                                    (
                                        role: any,
                                        index: React.Key | null | undefined
                                    ) => (
                                        <option
                                            key={index}
                                            className="absolute w-full"
                                            value={role.id}
                                            selected={role?.description
                                                ?.toLowerCase()
                                                .includes(
                                                    data.role?.toLowerCase()
                                                )}
                                        >
                                            {role.description}
                                        </option>
                                    )
                                )}
                        </select>
                    </div>
                </div>
            </div>
            <div className="btns flex justify-between mt-16">
                <Button
                    title="Deactivate"
                    onClick={() => { }}
                    styles="bg-[#F47B7B] px-4"
                />
                <Button
                    title="Reset Login"
                    onClick={() => { }}
                    styles="bg-[#B9B9B9] px-4"
                />
                <Button
                    title={loading ? "wait..." : "save"}
                    isLoading={loading}
                    onClick={handleCreateUser}
                    styles="bg-[#4F27F3] px-4"
                    disable={Object.keys(data).length > 0}
                />
            </div>
            <p className="text-center text-red-600 pt-2">{errorMessage}</p>
        </div>
    );
};
