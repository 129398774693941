import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaRedo } from "@react-icons/all-files/fa/FaRedo";
import { MdMessage } from "@react-icons/all-files/md/MdMessage";
import { DataStatus, TransactionType } from "../../types";

const Icons = ({
    onView,
    onRestart,
    onReply,
    transactionData,
}: {
    onView: () => void;
    onRestart: () => void;
    onReply: () => void;
    transactionData: TransactionType;
}) => {
    return (
        <>
            <button
                onClick={onView}
                className="bg-indigo-400 p-1 rounded cursor-pointer"
            >
                <FaEye color="white" size={20} />
            </button>
            <button
                disabled={
                    transactionData.status ===
                    DataStatus.completed_trans ||
                    transactionData.status === DataStatus.not_started_trans
                }
                onClick={onRestart}
                className="bg-indigo-400 p-1 rounded ml-5 disabled:bg-[#bbb] disabled:cursor-not-allowed "
            >
                <FaRedo color="white" size={20} />
            </button>
            <button
                onClick={onReply}
                className="bg-indigo-400 p-1 rounded cursor-pointer ml-5">
                <MdMessage color="white" size={20} />
            </button>
        </>
    );
};

export default Icons;
