import { USER_API_FAIL, CREATE_USER } from "../../constants/constants";

type Action = {
    type: string;
    data: any;
};

const initialState = {
    appError: null,
    status: null,
    userData: null,
};

const userReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case CREATE_USER: {
            return { ...state, status: "success", userData: action.data };
        }
        case USER_API_FAIL: {
            return {
                ...state,
                status: "failed",
                appError: "some error",
            };
        }
        default:
            return state;
    }
};
export default userReducer;
