import { combineReducers } from "redux";
import store from "../configureStore";

//Import reducers
import login from "./login";
import userReducer from "./user";
import role from "./role";
import transaction from "./transaction";
import statistics from "./statistics";
import toast from "./toastNotification";

const rootReducer = combineReducers({
    // list reducers,
    login: login,
    user: userReducer,
    role: role,
    transaction: transaction,
    statistics: statistics,
    toastNotifications: toast,
});

export default rootReducer;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
