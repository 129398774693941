import { FaLongArrowAltLeft } from "@react-icons/all-files/fa/FaLongArrowAltLeft";
import Button from "../button";
import { TransactionType } from "../../types";

interface PropTypes {
    onClickClose: () => void;
    transactionData: TransactionType;
    onReplyClick: () => void;
}

const ReplyTransactions = ({ ...props }: PropTypes) => {
    return (
        <div className="absolute top-[15%] right-[18%] bg-white  py-8 ">
            <button
                onClick={props.onClickClose}
                className="mx-10 text-gray-400"
            >
                <FaLongArrowAltLeft size={30} />
            </button>

            <div className="h-auto flex flex-col ml-8 px-8 py-7 px-5 relative">
                <p className="text-lg font-light mx-8 ">Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet consectetur adipiscing elit. Aliquam. This messgae will be sent to
                    {"\t"}
                    <strong>{props.transactionData.sender_number}</strong>
                </p>
                <textarea className="border-2 border-brown-200 mt-8 mx-8 p-12" placeholder="Enter the message..."
                    rows={8} cols={40} />

            </div>
            <div className="flex justify-end mr-16">
                <Button
                    title="Reply"
                    onClick={props.onReplyClick}
                    styles="bg-[#4F27F3] px-20  mb-8"
                />
            </div>
        </div>

    );
};

export default ReplyTransactions;