import { USER_ROLE_ID } from "../../constants/storageKeys";
import { RoleTypes } from "../../types";

const getUserRole = (role_id: string, roles: RoleTypes[]) => {
    if (roles.length > 0) {
        const userRole = roles?.find((role) => role.id === role_id);
        if (userRole) {
            localStorage.setItem(USER_ROLE_ID, JSON.stringify(userRole.id));
        }
        return userRole;
    }
};

export default getUserRole;
