type InputProps = {
    title: string;
    type: string;
    defaultValue?: string | number;
    onChange: (e: string) => void;
};

const Input = ({ title, type, onChange, defaultValue }: InputProps) => {
    return (
        <div className="flex flex-col gap-3">
            <label htmlFor="" className="font-semibold text-lg">
                {title}
            </label>
            <input
                defaultValue={defaultValue}
                type={type}
                onChange={(e) => onChange(e.target.value)}
                className="h-10 outline-none border border-[#B9B9B9] px-2 rounded-md"
            />
        </div>
    );
};

export default Input;
