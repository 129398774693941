import React from "react";

type CardProps = {
    image: string;
    title: string;
    onClick: () => void;
    hidden?: boolean;
};

const ServicesCard = ({ image, title, onClick, hidden }: CardProps) => {
    return (
        <div
            className={`flex flex-col gap-2 bg-[#f9f9f9] shadow-md py-5 px-7 hover:scale-105 duration-300 transition-all hover:cursor-pointer justify-center items-center rounded-lg ${
                hidden ? "hidden" : ""
            }`}
            onClick={onClick}
        >
            <img src={image} alt="" className="w-44   h-44 " />
            <p className="text-center text-xl font-semibold ">{title}</p>
        </div>
    );
};

export default ServicesCard;
