import { ROLE_API_FAIL, GET_ROLE } from "../constants/constants";
import { BuildApiRequest } from "../utils/helper";

// GET ROLE
export const getRolesPromise = async () => {
    try {
        const res = await BuildApiRequest("/roles", "GET", null, true);
        const data = await res.json();
        
        if (res.status !== 200) {
            throw new Error(data.message)
        }
        return data
    }
    catch (error) {
        return {
            "type": "error",
            "message": error instanceof Error ? error.message : error
        };
    }

}

export const getRole =
    () => (dispatch: any) => {
        return getRolesPromise()
            .then((resp) =>
                dispatch({
                    type: GET_ROLE,
                    data: resp,
                })
            )
            .catch((resp) =>
                dispatch({
                    type: ROLE_API_FAIL,
                    data: resp,
                })
            );
    };