export interface User {
	role: Role;
	user: {
		firstname: string;
		lastname: string;
	};
}

export enum Role {
	ADMIN = "admin",
	CUSTOMER_SERVICE = "cs",
}

export interface RoleType {
	description: string;
	id: string;
	name: string;
}

export enum TransactionStatus {
	ALL = "all",
	SUCCESS = "completed_trans",
	FAILED = "failed_trans", 
	PENDING = "pending_trans",
	NOT_STARTED = "not_started_trans",
}

export enum DataStatus {
	completed_trans = "COMPLETED",
    pending_trans = "PENDING",
    failed_trans = "FAILED",
    not_started_trans = "NOT_STARTED"
}

export interface TransactionType {
	id: string;
	amount: string;
	date: string;
	receiver_number: string;
	sender_number: string;
	status: string;
}

export interface UserTypes {
	firstname: string;
	lastname: string;
	email: string;
	password?: string;
	username: string;
	role_id: string;
}

export interface RoleTypes {
	description: string;
	id: string;
	name: string;
}

export interface NotificationData {
	id: string;
	title: string;
	type: NotificationStatus;
	message: string;
}

export enum NotificationStatus {
	SUCCESS = "SUCCESS",
	ERROR = "ERROR",
}

export interface NotificationData {
	id: string;
	title: string;
	type: NotificationStatus;
	message: string;
}
