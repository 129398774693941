export const emailDataList = [
    {
        senderName: 'John Doe',
        subject: 'Your transfer was successful',
        body: 'Congratulations the transfer of 5000 has been successfully sent to June Dubai. lorem ipsum Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, nostrum, exercitationem minima accusantium numquam nihil dicta suscipit accusamus ullam quas temporibus iusto voluptate qui molestias magni cum at odit distio. Congratulations the transfer of 5000 has been successfully sent to June Dubai. lorem ipsum Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, nostrum, exercitationem minima accusantium numquam nihil dicta suscipit accusamus ullam quas temporibus iusto voluptate qui molestias magni cum at odit distio. Congratulations the transfer of 5000 has been successfully sent to June Dubai. lorem ipsum Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, nostrum, exercitationem minima accusantium numquam nihil dicta suscipit accusamus ullam quas temporibus iusto voluptate qui molestias magni cum at odit distio. Congratulations the transfer of 5000 has been successfully sent to June Dubai. lorem ipsum Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, nostrum, exercitationem minima accusantium numquam nihil dicta suscipit accusamus ullam quas temporibus iusto voluptate qui molestias magni cum at odit distio.',
        date: 'April 4'
    },
    {
        senderName: 'Jane Doe',
        subject: 'Your transfer Failed',
        body: 'Sorry the transfer of 5000 sent to June Dubai has been cancelled. lorem ipsum Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, nostrum, exercitationem minima accusantium numquam nihil dicta suscipit accusamus ullam quas temporibus iusto voluptate qui molestias magni cum at odit distinctio. Sorry the transfer of 5000 sent to June Dubai has been cancelled. lorem ipsum Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, nostrum, exercitationem minima accusantium numquam nihil dicta suscipit accusamus ullam quas temporibus iusto voluptate qui molestias magni cum at odit distinctio',
        date: 'March 29'
    },
    {
        senderName: 'Barbie Land',
        subject: 'Your transfer pending',
        body: 'Pending, the transfer of 5000 sent to June Dubai is pending. Wait a while lorem ipsum Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, nostrum, exercitationem minima accusantium numquam nihil dicta suscipit accusamus ullam quas temporibus iusto voluptate qui molestias magni cum at odit distinctio.',
        date: 'March 28'
    },
    {
        senderName: 'Ken Doll',
        subject: 'Your transfer was successful',
        body: 'Congratulations the transfer of 5000 has been successfully sent to June Dubai. lorem ipsum Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, nostrum, exercitationem minima accusantium numquam nihil dicta suscipit accusamus ullam quas temporibus iusto voluptate qui molestias magni cum at odit distinctio.',
        date: 'March 3'
    },
    {
        senderName: 'Jane Doe',
        subject: 'Your transfer Pending',
        body: 'The transfer of 5000 sent to June Dubai is pending. lorem ipsum Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, nostrum, exercitationem minima accusantium numquam nihil dicta suscipit accusamus ullam quas temporibus iusto voluptate qui molestias magni cum at odit distinctio.',
        date: 'February 29'
    }
]