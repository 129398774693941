import { LOGIN_API_FAIL, LOGIN } from "../constants/constants";
import { BuildApiRequest } from "../utils/helper";

// USERLOGIN
const userLoginPromise = async (email: string, password: string) => {
    try {
        const res = await BuildApiRequest("/login", "POST", { email, password }, false);

        const results = await res.json();
        if (results.message) {
            throw new Error(results);
        }
        return results;
    } catch (error) {
        throw new Error("an error occured");
    }
};

export const userLogin =
    (email: string, password: string) => (dispatch: any) => {
        return userLoginPromise(email, password)
            .then((resp) =>
                dispatch({
                    type: LOGIN,
                    data: resp,
                })
            )
            .catch((resp) =>
                dispatch({
                    type: LOGIN_API_FAIL,
                    data: "Failed to login user",
                })
            );
    };