import { TRANSACTION_API_FAIL, GET_TRANSACTION, CLEAR_TRANSACTIONS } from "../../constants/constants";
import { FormatFullDate } from "../../utils/date";


type Action = {
    type: string,
    data?: any
}

const initialState = {
    appError: null,
    status: null,
    transactionData: []
};

const get_transaction = (state = initialState, action: Action) => {
    switch (action.type) {
        case GET_TRANSACTION: {


            for (const key in action.data) {
                action.data[key].created = FormatFullDate(action.data[key].created);
                action.data[key].amount = "XAF " + action.data[key].amount;
                action.data[key].receiver_number = action.data[key].receiver_number.toString().substring(3);
                action.data[key].sender_number = action.data[key].sender_number.toString().substring(3);
            }
            const data = action.data.map((data: any) => ({
                sender_number: data.sender_number,
                amount: data.amount,
                created: data.created,
                receiver_number: data.receiver_number,
                status: data.status,
                id: data.ID
            }))
            return {
                ...state,
                status: "success",
                transactionData: [...state.transactionData, ...data],

            }
        }
        case CLEAR_TRANSACTIONS: {
            return {
                ...state,
                status: null,
            }
        }
        case TRANSACTION_API_FAIL: {
            return {
                ...state,
                status: "failed",
                appError: "Erorr connecting to server"
            }
        }
        default:
            return state;
    }
};
export default get_transaction;