import React, { useState, useEffect } from "react";
import { getStatistics } from "../../services/queries";
import { GenerateDataSets, generateLebals } from "./Dataset";
import enableChartJS from "../../modules/dashboard/enableChart";
import { Role, TransactionStatus } from "../../types";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { FiltersSkeletonLoader, GraphSkeletonLoader } from "../loaders";
declare const Chart: any;

type PropType = {
	role?: string;
};

const StatsSection = ({ role }: PropType) => {
	const [status, setStatus] = useState<TransactionStatus>(
		TransactionStatus.ALL
	);
	const [duration, setDuration] = useState<string>("week");
	const statsData = useAppSelector(state => state.statistics.statisticsData)
	const appError = useAppSelector(state=> state.statistics.appError)
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();

	const getStatsData = async () => {
		setLoading(true);
		await dispatch(getStatistics({duration, status}))
	};

	useEffect(() => {
		getStatsData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [duration]);

	useEffect(() => {
		enableChartJS(); // enableling chartJs script for this component

		let myChart: any = null;

		const ctx: any = document.getElementById("myChart") as HTMLCanvasElement;

		// Check if a chart instance with the same ID already exists
		if (myChart) {
			myChart.destroy(); // Destroy the existing chart
		}

		if (!statsData || appError) {
			setLoading(true)
			return
		}

		setLoading(false)

		myChart = new Chart(ctx, {
			type: "line",
			data: {
				labels: statsData && generateLebals(statsData),
				datasets: statsData && [...GenerateDataSets(status, statsData)],
			},
			options: {
				plugins: {
					legend: {
						display: false, // Hide the legend (indicators on top of the graph)
					},
				},
				scales: {
					x: {
						position: "bottom",
						ticks: {
							align: "end",
							stepSize: 10
						},
						display: false,
						title: {
							display: true,
							text: "Date",
						},
					},
					y: {
						position: "left",
						ticks: {
							align: "start",
							stepSize: 10
						},
						title: {
							display: true,
							text: "Amount",
						},
						min: 0,
						max: 100
					},
				},
				maintainAspectRatio: false,
			},
		});
		// Clean up function to destroy the chart when the component unmounts
		return () => {
			if (myChart) {
				myChart.destroy();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, dispatch, statsData]);

	return (
		<div className="container flex w-full h-full gap-20 mt-5 relative justify-between">
		 {!role ? (
                <FiltersSkeletonLoader />
            ) : (<div className="flex flex-col bg-[#f9f9f9] pt-3 px-4 pb-5 rounded-md">
				<h2 className="text-lg font-semibold">Filters</h2>
				<div className="filters ">
					<div className="status mt-4  ">
						<h3 className="text-xs underline-offset-1 underline">Status</h3>
						<div className="flex gap-3 items-center w-64 flex-wrap mt-4">
							<button
								onClick={() => setStatus(TransactionStatus.ALL)}
								className={`text-xs py-1 px-3 w-[75px] rounded-full border border-[#898585] ${
									status === "all" ? " bg-[#898585] text-white" : ""
								} transition-all duration-300`}
							>
								All
							</button>
							<button
								disabled={role !== Role.ADMIN}
								onClick={() => setStatus(TransactionStatus.FAILED)}
								className={`text-xs py-1 px-3 w-[75px] rounded-full border border-[#F47B7B]  ${
									status === TransactionStatus.FAILED ? " bg-[#F47B7B] text-white" : ""
								} transition-all duration-300   disabled:hidden`}
							>
								Failed
							</button>
							<button
								onClick={() => setStatus(TransactionStatus.PENDING)}
								disabled={role !== Role.ADMIN}
								className={`text-xs py-1 px-3 w-[75px] rounded-full border border-[#F5D00C] ${
									status === TransactionStatus.PENDING ? " bg-[#F5D00C] text-white" : ""
								} transition-all duration-300   disabled:hidden`}
							>
								Pending
							</button>
							<button
								onClick={() => setStatus(TransactionStatus.SUCCESS)}
								disabled={role !== Role.ADMIN}
								className={`text-xs py-1 px-3 w-[75px] rounded-full border border-[#47EE76] ${
									status === TransactionStatus.SUCCESS ? " bg-[#47EE76] text-white" : ""
								} transition-all duration-300   disabled:hidden`}
							>
								Success
							</button>
							<button
								onClick={() => setStatus(TransactionStatus.NOT_STARTED)}
								disabled={role !== Role.ADMIN}
								className={`text-xs py-1 px-3  rounded-full border border-[#3A3A3A] ${
									status === TransactionStatus.NOT_STARTED ? " bg-[#3A3A3A] text-white" : ""
								} transition-all duration-300   disabled:hidden`}
							>
								Not started
							</button>
						</div>
					</div>

					<div className="dates mt-9">
						<h3 className="text-xs underline-offset-1 underline  py-3">
							Dates
						</h3>
						<div className="grid grid-cols-3 space-x-3">
							<button
								onClick={() => setDuration("week")}
								disabled={role !== Role.ADMIN}
								className={` text-xs py-1 px-3  rounded-full border border-[#898585] ${
									duration === "week" ? "bg-[#898585] text-white" : ""
								} transition-all duration-300   disabled:hidden`}
							>
								This week
							</button>

							<button
								onClick={() => setDuration("month")}
								disabled={role !== Role.ADMIN}
								className={`text-xs py-1 px-3  rounded-full border border-[#898585] ${
									duration === "month" ? "bg-[#898585] text-white" : ""
								} transition-all duration-300  disabled:hidden`}
							>
								This month
							</button>

							<button
								onClick={() => setDuration("year")}
								className={`text-xs py-1 px-3  rounded-full border border-[#898585] ${
									duration === "year"
										? "bg-[#898585] text-white"
										: ""
								} transition-all duration-300  `}
							>
								This Year
							</button>
						</div>
						<button
							onClick={() => {
								getStatsData();
							}}
							disabled={loading}
							className={`bg-[#4F27F3] disabled:bg-[#898585] disabled:cursor-wait border text-xs text-white px-5 rounded-full py-1 mt-10`}
						>
							Refresh graph
						</button>
					</div>
				</div>
			</div>
		)}
			<div
				id="chart"
				className="w-[70%] ml-10 h-full absolute flex flex-col items-end justify-end right-0"
			>
				<canvas id="myChart"></canvas>
			</div>
			{loading && <GraphSkeletonLoader />}
		</div>
	);
};

export default StatsSection;
