export const UserData = [
    {
        id: "1234567890",
        firstname: "Jane",
        lastname: "Doe",
        username: "Jane",
        email: "janedoe@gmail.com",
        role: "Customer Service",
    },
    {
        id: "2345678901",
        firstname: "John",
        lastname: "Doe",
        username: "John",
        email: "johndoe@gmail.com",
        role: "Admin",
    },
    {
        id: "3456789012",
        firstname: "Jenny",
        lastname: "Carter",
        username: "Jenny",
        email: "jennycarter@gmail.com",
        role: "Customer Service",
    },
    {
        id: "4567890123",
        firstname: "Ken",
        lastname: "Doll",
        username: "Ken",
        email: "kendoll@gmail.com",
        role: "Admin",
    },
    {
        id: "5678901234",
        firstname: "Barbie",
        lastname: "Land",
        username: "Barbie",
        email: "barbieland@gmail.com",
        role: "Customer Service",
    },
];
