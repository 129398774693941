import { ROLE_API_FAIL, GET_ROLE } from "../../constants/constants";


type Action = {
    type: string,
    data?: any
}

const initialState = {
    roleError: null,
    status: null,
    role: null
};

const get_role = (state = initialState, action: Action) => {
    switch (action.type) {
        case GET_ROLE: {
            if (action.data.type === "error") {
                return {
                    ...state,
                    status: "failed",
                    role: null,
                    roleError: action.data.message
                }
            }
            return {
                ...state,
                status: "success",
                role: action.data
            }
        }

        case ROLE_API_FAIL: {
            return {
                ...state,
                status: "failed",
                roleError: "Erorr connecting to server"
            }
        }
        default:
            return state;
    }
};
export default get_role;