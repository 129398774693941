import { FaLongArrowAltLeft } from "@react-icons/all-files/fa/FaLongArrowAltLeft";
import { FaLongArrowAltDown } from "@react-icons/all-files/fa/FaLongArrowAltDown";
import React from "react";
import { DataStatus, TransactionType } from "../../types";
import Button from "../button";
interface PropTypes {
    onClickClose: () => void;
    transactionData: TransactionType;
    onRestartClick: () => void;
}

const RestartTransactions = ({ ...props }: PropTypes) => {
    return (
        <div className="absolute top-[15%] right-[28%] bg-white  py-12 ">
            <button
                onClick={props.onClickClose}
                className="mx-10 text-gray-400"
            >
                <FaLongArrowAltLeft size={30} />
            </button>
            <div className="w-full  px-40">
                <h3 className="text-center font-semibold text-xl pt-3">
                    Are you sure you want to restart the following transaction
                </h3>
                <div className="w-full my-14 justify-center items-center flex flex-col">
                    <div className="max-w-60 w-full flex flex-col gap-5">
                        <div className="w-full flex justify-between items-center">
                            <h3 className="font-semibold">Sender Number:</h3>
                            <span className="text-sm">
                                {props.transactionData.sender_number}
                            </span>
                        </div>
                        <div className="flex w-full justify-between">
                            <h3 className="text-lg font-semibold">Status:</h3>
                            <p
                                className={`text-sm ${
                                    props.transactionData.status ===
                                    DataStatus.failed_trans
                                        ? "text-red-500"
                                        : ""
                                }  ${
                                    props.transactionData.status ===
                                    DataStatus.completed_trans
                                        ? "text-green-400"
                                        : ""
                                } ${
                                    props.transactionData.status ===
                                    DataStatus.pending_trans
                                        ? "text-yellow-500"
                                        : ""
                                }`}
                            >
                                {props.transactionData.status.toUpperCase()}
                            </p>
                        </div>
                        <div className="w-full flex justify-between items-center">
                            <h3 className="font-semibold">Created:</h3>
                            <span className="text-sm">
                                {props.transactionData.date}
                            </span>
                        </div>
                    </div>
                    <div className="py-5 font-extrabold text-[#4F27F3]">
                        <FaLongArrowAltDown
                            size={50}
                            style={{ width: "200px" }}
                        />
                    </div>
                    <div className="max-w-60 w-full flex flex-col gap-5">
                        <div className="w-full flex justify-between items-center">
                            <h3 className="font-semibold">Reciever Number:</h3>
                            <span className="text-sm">
                                {props.transactionData.receiver_number}
                            </span>
                        </div>
                        <div className="flex w-full justify-between">
                            <h3 className="text-lg font-semibold">Status:</h3>
                            <p
                                className={`text-sm  ${
                                    props.transactionData.status ===
                                    DataStatus.failed_trans
                                        ? "text-red-500"
                                        : ""
                                }  ${
                                    props.transactionData.status ===
                                    DataStatus.completed_trans
                                        ? "text-green-400"
                                        : ""
                                } ${
                                    props.transactionData.status ===
                                    DataStatus.pending_trans
                                        ? "text-yellow-500"
                                        : ""
                                }`}
                            >
                                {props.transactionData.status.toUpperCase()}
                            </p>
                        </div>
                        <div className="w-full flex justify-between items-center">
                            <h3 className="font-semibold">Created:</h3>
                            <span className="text-sm">
                                {props.transactionData.date}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-end items-end mt-16 mr-10">
                <Button
                    title="Restart"
                    onClick={() => {}}
                    styles={"bg-[#4F27F3]"}
                />
            </div>
        </div>
    );
};

export default RestartTransactions;
