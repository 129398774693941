import React from "react";

export const Overlay = ({ onClick }: { onClick: () => void }) => {
    return (
        <div
            onClick={onClick}
            className="bg-[#939292] fixed w-full left-0 h-[1068px] opacity-80 top-0 "
        ></div>
    );
};
