import { STATISTICS_API_FAIL, GET_STATISTICS } from "../constants/constants";
import { BuildApiRequest } from "../utils/helper";

type PropType = {
    date?: string;
    duration: string
    status: string
};

export const getDataPromise = async ({ date, duration, status }: PropType) => {
    try {
        const res = await BuildApiRequest(`/statistics?status=${status}&duration=${duration}`, "GET", date, true);
        return await res.json();
    } catch (error) {
        return { "error fetching data": error };
    }
};

export const getStatistics =  ({duration, status}: PropType) => (dispatch: any) => {
    return getDataPromise({duration, status})
        .then((resp) =>{
            if(resp.error){
                dispatch({
                    type: STATISTICS_API_FAIL,
                    data: resp.message,
                })
            }
            dispatch({
                type: GET_STATISTICS,
                data: resp,
            })}
        )
};
