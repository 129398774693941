import React from "react";

type BtnProps = {
    title: string;
    onClick?: () => void;
    isLoading?: boolean;
    styles: string;
    disable?: boolean;
};

const Button = ({
    title,
    onClick,
    isLoading,
    styles,
    disable = false,
}: BtnProps) => {
    return (
        <button
            onClick={onClick}
            disabled={isLoading || disable}
            className={` text-white disabled:cursor-not-allowed disabled:bg-[#BBBBBB] py-3 min-w-32 ${styles} rounded-md`}
        >
            {title}
        </button>
    );
};

export default Button;
