import { IoIosArrowBack } from "@react-icons/all-files/io/IoIosArrowBack";
import { IoIosArrowForward } from "@react-icons/all-files/io/IoIosArrowForward";

type PropTypes = {
    startIndex: number;
    endIndex: number;
    onClickNext: () => void;
    onClickPrev: () => void;
    totalCount: number;
};

export const Paginator = ({
    startIndex,
    endIndex,
    onClickPrev,
    onClickNext,
    totalCount,
}: PropTypes) => {
    return (
        <div className="flex justify-end my-5 self-end items-center w-full mr-12">
            <div className="flex-row flex items-center">
                {totalCount > endIndex ? (
                    <p>
                        {startIndex}-{totalCount > endIndex} of {totalCount}
                    </p>
                ) : (
                    <p>
                        {totalCount} {totalCount && <span>of</span>}{" "}
                        {totalCount}
                    </p>
                )}
                <button
                    disabled={startIndex === 1 || !totalCount}
                    onClick={onClickPrev}
                    className="hover:bg-gray-200 py-2 px-4 border disabled:cursor-not-allowed disabled:text-gray-300 border-gray-200 ml-5"
                >
                    <IoIosArrowBack />
                </button>
                <button
                    disabled={endIndex >= totalCount || !totalCount}
                    onClick={onClickNext}
                    className="hover:bg-gray-200 py-2 disabled:cursor-not-allowed disabled:text-gray-300 px-4 border border-gray-200"
                >
                    <IoIosArrowForward />
                </button>
            </div>
        </div>
    );
};
