import { ADD_NOTIFICATION, DELETE_NOTIFICATION } from "../constants/constants";
import { NotificationStatus } from "../types";

type addNotificationType = {
    title: string;
    type: NotificationStatus;
    message: string;
};

export const addNotification = ({
    title,
    type,
    message,
}: addNotificationType) => {
    return {
        type: ADD_NOTIFICATION,
        data: {
            id: Date.now().toString(),
            title,
            type,
            message,
        },
    };
};

export const deleteNotification = (id: string) => {
    return {
        type: DELETE_NOTIFICATION,
        data: id,
    };
};
