import { USER_API_FAIL, CREATE_USER } from "../constants/constants";
import { UserTypes } from "../types";
import { BuildApiRequest } from "../utils/helper";

// GET USER
export const getUser = async (id: string) => {
    try {
        const res = await BuildApiRequest("/user", "GET", { id }, false);
        const results = await res.json();
        return results;

    } catch (error: any) {
        throw new Error("error trying to fetch user", error);
    }
};

// create user promise
const createNewUserPromise = async (userData: UserTypes) => {
    try {
        const res = await BuildApiRequest("/user", "GET", userData, false);

        const results = await res.json();
        if (results.message) {
            throw new Error(results);
        }
        return results;
    } catch (error) {
        return error;
    }
};

export const createNewUser = (userData: UserTypes) => (dispatch: any) => {
    return createNewUserPromise(userData)
        .then((resp) =>
            dispatch({
                type: CREATE_USER,
                data: resp,
            })
        )
        .catch((resp) =>
            dispatch({
                type: USER_API_FAIL,
                data: resp,
            })
        );
};