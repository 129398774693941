import { useEffect, useState } from "react";
import * as ROUTES from "../../constants/routes";
import { Role, RoleTypes } from "../../types";
import { IoMdHome } from "@react-icons/all-files/io/IoMdHome";
import { BsArrowLeftRight } from "@react-icons/all-files/bs/BsArrowLeftRight";
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";
import { FaUser } from "@react-icons/all-files/fa/FaUser";
import { BsFillBarChartFill } from "@react-icons/all-files/bs/BsFillBarChartFill";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import getUserRole from "../../modules/dashboard/getUserRole";
import { USER_ROLE_ID } from "../../constants/storageKeys";

const Sidebar = () => {
    const roles = useAppSelector((state) => state.role.role);
    const [currentUserRole, setCurrentUserRole] = useState<RoleTypes>();
    const data = localStorage.getItem(USER_ROLE_ID);
    const role_id = !!data ? JSON.parse(data): '';

    useEffect(() => {
        if (roles) {
            const role = getUserRole(role_id, roles);
            setCurrentUserRole(role);
        }
    }, [roles, role_id]);

    const menuList = [
        {
            name: "Dashboard",
            path: ROUTES.DASHBOARD,
            icon: <IoMdHome size={30} />,
            role: Role.CUSTOMER_SERVICE || Role.ADMIN,
        },
        {
            name: "Transactions",
            path: ROUTES.TRANSACTIONS,
            icon: <BsArrowLeftRight size={30} />,
            role: Role.CUSTOMER_SERVICE || Role.ADMIN,
        },
        {
            name: "Messages",
            path: ROUTES.EMAILS,
            icon: <FaEnvelope size={30} />,
            role: Role.CUSTOMER_SERVICE || Role.ADMIN,
        },
        {
            name: "Users",
            path: ROUTES.USERS,
            icon: <FaUser size={30} />,
            role: Role.ADMIN,
        },
        {
            name: "Report",
            path: "",
            icon: <BsFillBarChartFill size={30} />,
            role: Role.ADMIN,
        },
    ];

    const pathName = useLocation().pathname;

    return (
        <div className="bg-white pt-10">
            {currentUserRole &&
                menuList.map((menu, index) => (
                    <Link
                        to={menu.path}
                        key={index}
                        className={`

                        ${currentUserRole.name !== Role.ADMIN &&
                                menu.role === Role.ADMIN
                                ? "hidden"
                                : ""
                            }
                        ${pathName === menu.path ? "bg-[#F4F2F2]" : ""
                            } flex px-8 gap-3 py-4 items-center text-xl hover:bg-[#F4F2F2] font-bold mb-7 hover:cursor-pointer `}
                    >
                        {menu.icon}
                        <span>{menu.name}</span>
                    </Link>
                ))}
        </div>
    );
};

export default Sidebar;
