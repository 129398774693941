import React, { useEffect, useState } from "react";
import Table from "../table/table";
import { DataStatus, NotificationStatus, TransactionStatus, TransactionType } from "../../types";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getTransaction, clearTransactions } from "../../services/transaction";
import TransactionDetails from "./transactionDetails";
import RestartTransactions from "./restartTransactions";
import ReplyTransactions from "./replyTransactions";
import { Overlay } from "../overlay";
import Icons from "./Icons";
import Searchbar from "../searchbar/searchbar";
import { TransactionSkeletonLoader } from "../loaders";
import { addNotification } from "../../utils/notification";

const TransactionList = () => {
    const [openTab, setOpenTab] = React.useState(1);
    const [openTransaction, setOpenTransaction] = useState(false);
    const [openRestartTransactions, setOpenRestartTransactions] =
        useState(false);
    const [openReplyTransactions, setOpenReplyTransactions] = useState(false);
    const [transactionData, setTransactionData] =
        useState<TransactionType | null>(null);

    const transactionDataValue = useAppSelector(
        (state) => state.transaction.transactionData
    );
    const transactionDataStatus = useAppSelector(
        (state) => state.transaction.status
    );

    const [search, setSearch] = useState(""); // Search value used to oly display content in the search bar
    const [start, setStart] = useState<number>(1);
    const [transactionListData, setTransactionListData] = useState<TransactionType[]>(transactionDataValue);
    const limit = 5;

    // const searchTransactions = useState<String>;
    const tableHeaderData = [
        "Sender No",
        "Amount",
        "Date",
        "Receiver No",
        "Status",
        "id",
        "Actions",
    ];

    const dispatch = useAppDispatch();

    const handleSearchTransactions = () => {

        const searchResult = transactionListData.filter((item: TransactionType) => {
            return item.receiver_number.includes(search) || item.sender_number.includes(search);
        });

        if (searchResult.length === 0) {
            getNewData();
        }
        setTransactionListData(searchResult);

    }

    const getNewData = () => {
        const newStart = start + limit;
        dispatch(clearTransactions());
        dispatch(getTransaction({ start: newStart, limit }));
        setStart(newStart);
    }

    useEffect(() => {
        const handleTransaction = () => {
            dispatch(getTransaction({ start, limit }));
        };

        handleTransaction();
    }, [start, dispatch]);

    useEffect(() => {
        let searchResult = transactionDataValue;
        if (transactionDataValue || search.length === 0) {
            searchResult = transactionDataValue.filter((item: TransactionType) => {
                return item.receiver_number.includes(search) || item.sender_number.includes(search);
            });
            setTransactionListData(searchResult);
        }

        if (transactionDataStatus === 'failed') {
            dispatch(
                addNotification({
                    title: "Opps! Could not get transactions",
                    type: NotificationStatus.ERROR,
                    message: "Error connecting to server",
                })
            );
        }

    }, [transactionDataValue, search, transactionDataStatus, dispatch]);


    const sortedData = (status: string) => {
        if (transactionListData) {
            switch (status.toLowerCase()) {
                case TransactionStatus.FAILED:
                    return transactionListData.filter(
                        (item: TransactionType) =>
                            item.status === DataStatus.failed_trans
                    );

                case TransactionStatus.PENDING:
                    return transactionListData.filter(
                        (item: TransactionType) =>
                            item.status === DataStatus.pending_trans
                    );

                case TransactionStatus.SUCCESS:
                    return transactionListData.filter(
                        (item: TransactionType) =>
                            item.status === DataStatus.completed_trans
                    );
                default:
                    return transactionListData;
            }
        }
    };

    const updateTransactionData = () => {
        // fetch and update data

    };

    return (
        <>
            <Searchbar
                search={search}
                setSearch={setSearch}
                onButtonClick={handleSearchTransactions}
            />

            <div className="flex flex-wrap justify-center items-center w-full">
                <div className="w-full mx-5 my-3">
                    <ul
                        className="hidden font-normal text-center text-2xl sm:flex cursor-pointer mx-12"
                        role="tablist"
                    >
                        <li
                            className={
                                "w-full p-4 bg-white-100 border focus:outline-none hover:cursor-pointer" +
                                (openTab === 1
                                    ? "text-indigo-400 border-indigo-400"
                                    : "bg-white")
                            }
                            onClick={(e) => {
                                e.preventDefault();
                                setOpenTab(1);
                            }}
                            data-toggle="tab"
                            role="tablist"
                        >
                            <p
                                className={
                                    " " +
                                    (openTab === 1
                                        ? "text-indigo-400"
                                        : "bg-white")
                                }
                            >
                                All
                            </p>
                        </li>
                        <li
                            className={
                                "w-full p-4 bg-white-100 border focus:outline-none hover:cursor-pointer" +
                                (openTab === 2
                                    ? "text-indigo-400 border-indigo-400"
                                    : "bg-white")
                            }
                            onClick={(e) => {
                                e.preventDefault();
                                setOpenTab(2);
                            }}
                            data-toggle="tab"
                            role="tablist"
                        >
                            <p
                                className={
                                    " " +
                                    (openTab === 2
                                        ? "text-indigo-400"
                                        : "bg-white")
                                }
                            >
                                Failed
                            </p>
                        </li>
                        <li
                            className={
                                "w-full p-4 bg-white-100 border focus:outline-none hover:cursor-pointer" +
                                (openTab === 3
                                    ? "text-indigo-400 border-indigo-400"
                                    : "bg-white")
                            }
                            onClick={(e) => {
                                e.preventDefault();
                                setOpenTab(3);
                            }}
                            data-toggle="tab"
                            role="tablist"
                        >
                            <p
                                className={
                                    " " +
                                    (openTab === 3
                                        ? "text-indigo-400"
                                        : "bg-white")
                                }
                            >
                                Pending
                            </p>
                        </li>
                    </ul>
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                        {!transactionDataStatus ? (
                            <TransactionSkeletonLoader />
                        ) :
                            (<div className="">
                                <div className="tab-content tab-space">
                                    <div
                                        className={
                                            openTab === 1 ? "block" : "hidden"
                                        }
                                        id="link1"
                                    >
                                        <Table
                                            tableHeader={tableHeaderData}
                                            showIcon={true}
                                            data={sortedData(TransactionStatus.ALL)}
                                            updateData={updateTransactionData}
                                            icons={({ row }) => (
                                                <Icons
                                                    transactionData={row}
                                                    onView={() => {
                                                        setTransactionData(row);
                                                        setOpenTransaction(true);
                                                    }}
                                                    onRestart={() => {
                                                        setTransactionData(row);
                                                        setOpenRestartTransactions(
                                                            true
                                                        );
                                                    }}
                                                    onReply={() => {
                                                        setTransactionData(row);
                                                        setOpenReplyTransactions(true);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div
                                        className={
                                            openTab === 2 ? "block" : "hidden"
                                        }
                                        id="link2"
                                    >
                                        <Table
                                            tableHeader={tableHeaderData}
                                            showIcon={true}
                                            data={sortedData(
                                                TransactionStatus.FAILED
                                            )}
                                            updateData={updateTransactionData}
                                            icons={({ row }) => (
                                                <Icons
                                                    transactionData={row}
                                                    onView={() => {
                                                        setTransactionData(row);
                                                        setOpenTransaction(true);
                                                    }}
                                                    onRestart={() => {
                                                        setTransactionData(row);
                                                        setOpenRestartTransactions(
                                                            true
                                                        );
                                                    }}
                                                    onReply={() => {
                                                        setTransactionData(row);
                                                        setOpenReplyTransactions(true);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div
                                        className={
                                            openTab === 3 ? "block" : "hidden"
                                        }
                                        id="link3"
                                    >
                                        <Table
                                            tableHeader={tableHeaderData}
                                            showIcon={true}
                                            data={sortedData(
                                                TransactionStatus.PENDING
                                            )}
                                            updateData={updateTransactionData}
                                            icons={({ row }) => (
                                                <Icons
                                                    transactionData={row}
                                                    onView={() => {
                                                        setTransactionData(row);
                                                        setOpenTransaction(true);
                                                    }}
                                                    onRestart={() => {
                                                        setTransactionData(row);
                                                        setOpenTransaction(false);
                                                        setOpenRestartTransactions(
                                                            true
                                                        );
                                                    }}
                                                    onReply={() => {
                                                        setTransactionData(row);
                                                        setOpenTransaction(false);
                                                        setOpenRestartTransactions(false);
                                                        setOpenReplyTransactions(true);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            )}
                    </div>
                </div>
                {transactionData && openTransaction && (
                    <div className="absolute top-0 bg-white w-full h-full">
                        <TransactionDetails
                            transactionData={transactionData}
                            onRestartClick={() => {
                                setOpenRestartTransactions(true);
                            }}
                            onClickClose={() => setOpenTransaction(false)}
                        />
                    </div>
                )}
                {openRestartTransactions && transactionData && (
                    <>
                        <Overlay
                            onClick={() => setOpenRestartTransactions(false)}
                        />
                        <RestartTransactions
                            onClickClose={() =>
                                setOpenRestartTransactions(false)
                            }
                            transactionData={transactionData}
                            onRestartClick={() => { }}
                        />
                    </>
                )}
                {openReplyTransactions && transactionData && (
                    <>
                        <Overlay
                            onClick={() => setOpenReplyTransactions(false)}
                        />
                        <ReplyTransactions
                            onClickClose={() =>
                                setOpenReplyTransactions(false)
                            }
                            transactionData={transactionData}
                            onReplyClick={() => { }}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default TransactionList;
