import { Paginator } from "../paginator";
import { useState } from "react";
import { DataStatus, TransactionType } from "../../types";
import { TransactionSkeletonLoader } from "../loaders";

interface TableProps {
    data?: TransactionType[] | any;
    tableHeader: string[];
    showIcon: boolean;
    rowOnClick?: (row: any) => void;
    updateData?: () => void;
    icons?: (props: { row: TransactionType }) => JSX.Element;
}

const Table = ({ icons: Icons, ...props }: TableProps) => {
    const range = 10;
    const [startIndex, setStartIdex] = useState<number>(1);
    const [endIndex, setEndIndex] = useState(range);

    const styleStatusColumn = (status: string): string => {
        switch (status) {
            case DataStatus.failed_trans:
                return "text-red-500";
        }
        switch (status) {
            case DataStatus.pending_trans:
                return "text-yellow-500";
        }
        switch (status) {
            case DataStatus.completed_trans:
                return "text-green-500";

            default:
                return "text-white-900";
        }
    };


    const dataCount = props.data && props.data.length;

    const handleNext = () => {
        if (endIndex >= dataCount) {
            if (props.updateData) {
                props.updateData();
            }
            return;
        }
        setStartIdex((prev) => prev + range);
        setEndIndex((prev) => prev + range);
    };

    const handlePrev = () => {
        if (startIndex === 1) return;
        setStartIdex((prev) => prev - range);
        setEndIndex((prev) => prev - range);
    };

    return (
        <>
            {!props.data ? <TransactionSkeletonLoader /> :
                <div className="flex flex-col">
                    <div className="flex flex-col mx-12">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                <div className="overflow-hidden">
                                    <table className="min-w-full border text-left text-sm font-light">
                                        <thead className="border-b bg-neutral-50 font-medium">
                                            <tr>
                                                {props.tableHeader.map(
                                                    (name: string, index: number) => (
                                                        <th
                                                            key={index}
                                                            scope="col"
                                                            className="border-r px-6 py-4 uppercase"
                                                        >
                                                            {name}
                                                        </th>
                                                    )
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.data &&
                                                props.data
                                                    .slice(startIndex - 1, endIndex)
                                                    .map((item: any, index: number) => (
                                                        <tr
                                                            key={index}
                                                            onClick={() =>
                                                                props.rowOnClick &&
                                                                props.rowOnClick(item)
                                                            }
                                                            className="border-b hover:bg-[#F4F2F2] hover:cursor-pointer duration-300"
                                                        >
                                                            {Object.keys(item).map(
                                                                (
                                                                    key: string,
                                                                    index: number
                                                                ) =>
                                                                    key === "status" ? (
                                                                        <td
                                                                            key={index}
                                                                            className={`whitespace-nowrap border-r px-6 py-4 font-normal ${styleStatusColumn(
                                                                                item[
                                                                                key
                                                                                ]
                                                                            )}`}
                                                                        >
                                                                            {item[key]}
                                                                        </td>
                                                                    ) : (
                                                                        <td
                                                                            key={index}
                                                                            className="whitespace-nowrap border-r px-6 py-4 font-normal"
                                                                        >
                                                                            {item[key]}
                                                                        </td>
                                                                    )
                                                            )}
                                                            {props.showIcon ? (
                                                                <td className="whitespace-nowrap border-r px-6 py-4 flex items-center justify-between">
                                                                    {Icons && (
                                                                        <Icons
                                                                            row={item}
                                                                        />
                                                                    )}
                                                                </td>
                                                            ) : null}
                                                        </tr>
                                                    ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Paginator
                        startIndex={startIndex}
                        endIndex={endIndex}
                        onClickNext={handleNext}
                        onClickPrev={handlePrev}
                        totalCount={dataCount}
                    />
                </div>
            }
        </>
    );
};
export default Table;
