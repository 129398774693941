import { GET_STATISTICS, STATISTICS_API_FAIL } from "../../constants/constants";

type Action = {
    type: string;
    data?: any;
};

const initialState = {
    appError: null,
    status: null,
    statisticsData: null,
};

const get_statistics = (state = initialState, action: Action) => {
    switch (action.type) {
        case GET_STATISTICS: {
            return {
                ...state,
                status: "success",
                statisticsData: action.data,
            };
        }
        case STATISTICS_API_FAIL: {
            return {
                ...state,
                status: "failed",
                appError: "Erorr connecting to server"
            };
        }

        default:
            return state;
    }
};

export default get_statistics