export const GET_USER = "GET_USER";
export const GET_STATISTICS = "GET_STATISTICS";
export const LOGIN = "LOGIN";
export const CREATE_USER = "CREATE_USER";
export const GET_ROLE = "GET_ROLE";
export const GET_TRANSACTION = "GET_TRANSACTION";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const ROLE_API_FAIL = "ROLE_API_FAIL";
export const CLEAR_TRANSACTIONS = "CLEAR_TRANSACTIONS";
export const LOGIN_API_FAIL = "LOGIN_API_FAIL";
export const STATISTICS_API_FAIL = "STATISTICS_API_FAIL";
export const USER_API_FAIL = "USER_API_FAIL";
export const TRANSACTION_API_FAIL = "TRANSACTION_API_FAIL";
