import React, { useEffect, useState, useCallback } from "react";
import Input from "../../components/input";
import Button from "../../components/button";
import { userLogin } from "../../services/login";
import { LOCAL_STORAGE } from "../../utils/storage";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { DASHBOARD } from "../../constants/routes";
import * as KEYS  from "../../constants/storageKeys";


const Login = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [laoding, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMassage] = useState<null | string>(null);
    const [loginError, setLoginError] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.login.userData);
    const status = useAppSelector((state) => state.login.status);
    const error = useAppSelector((state) => state.login.appError);


    // handle user login with email and password credentials
    const handleLogin = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setLoading(true);

        if (!email || !password) {
            setLoginError(true);
            setLoading(false);
            return;
        }
        setErrorMassage("");

        dispatch(userLogin(email, password));
    };

    const moveToDashboard = useCallback(() => {
        setLoading(false);
        navigate(DASHBOARD);
    }, [navigate]);

    useEffect(() => {
        if (status === "success" && !error) {
            LOCAL_STORAGE.set(KEYS.USER_FIRSTNAME, JSON.stringify(user.user.firstname));
            LOCAL_STORAGE.set(KEYS.USER_ROLE_ID, JSON.stringify(user.user.role));
            LOCAL_STORAGE.set(KEYS.USER_TOKEN, JSON.stringify(user.access_token));
            LOCAL_STORAGE.set(KEYS.USER_REFRESH, JSON.stringify(user.refresh_token));

            setLoading(false);
            navigate(DASHBOARD);
        }
        else if (status === "failed" && error !== null) {
            setLoading(false);
            setLoginError(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, error, status, moveToDashboard]);

    return (

        <div className="w-full h-full flex flex-col items-center mt-20">
            <form
                onSubmit={handleLogin}
                className="w-[700px] h-[580px] shadow-lg px-14 my-auto py-8"
            >
                <div>
                    {loginError ?
                        <div className="bg-red-400 text-white mb-8 mt-6">
                            <p className="font-semibold text-sm text-center py-3 px-32">Login Failed! Incorrect username or Password</p>
                        </div>
                        : null
                    }
                </div>
                <h1 className="text-center underline-offset-1 underline text-2xl font-semibold">
                    Login
                </h1>

                <div className="flex flex-col gap-7 mt-16">
                    <Input
                        title="Email:"
                        type="email"
                        onChange={(e) => setEmail(e)}
                    />
                    <Input
                        title="Password:"
                        type="password"
                        onChange={(e) => setPassword(e)}
                    />
                </div>

                <div className="flex  justify-center mt-12">
                    <Button
                        styles="bg-[#4F27F3] px-20"
                        isLoading={laoding}
                        title={`${laoding ? "Loading..." : "Login"}`}
                    />
                </div>
                <p className="text-center py-2 text-red-600">{errorMessage}</p>
            </form>
        </div>
    );
};
export default Login;
