import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Transactions from './modules/transactions/transactions';
import Dashboard from './modules/dashboard/dashboard';
import Login from "./modules/login/login";
import * as ROUTES from "./constants/routes"
import Users from './modules/users/users';
import Emails from "./modules/email/email";
import Layout from './layout/layout';



const Router = () => {
  return (
    <BrowserRouter basename={ROUTES.DASHBOARD}>
      <Routes>
        <Route path={ROUTES.DASHBOARD} element={<Layout><Dashboard /></Layout>} />
        <Route path={ROUTES.TRANSACTIONS} element={<Layout><Transactions /></Layout>} />
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.USERS} element={<Layout><Users /></Layout>} />
        <Route path={ROUTES.EMAILS} element={<Layout><Emails /></Layout>} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
