import { LOGIN_API_FAIL, LOGIN } from "../../constants/constants";



type Action = {
	type: string,
	data?: any
}

const initialState = {
	appError: null,
	status: null,
	userData: null
};

const login = (state = initialState, action: Action) => {
	switch (action.type) {
		case LOGIN: {
			return {
				...state,
				status: "success",
				userData: action.data
			}
		}
		case LOGIN_API_FAIL: {
			return {
				...state,
				status: "failed",
				appError: "Erorr connecting to server"
			}
		}
		default:
			return state;
	}
};
export default login;