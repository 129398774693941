export class LOCAL_STORAGE {
    static set(key: string, value: string) {
        return localStorage.setItem(key, value);
    }

    static get(key: string): string | undefined | null {
        if (!key) {
            return
        }

        return localStorage.getItem(key);
    }
}

export const removeStorageItem = (key: string) => {
    return localStorage.removeItem(key);
}

export const clearStorage = () => {
    return localStorage.clear();
}
