import { GetCurrentYear } from "../../utils/date";

const Footer = () => {
    return (
        <div className="w-full bg-gray-800 text-center py-11">
            <p className="text-white">@{GetCurrentYear()} Admin Panel</p>
        </div>
    );
};

export default Footer;
