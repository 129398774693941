import { useState } from "react";
// import Searchbar from "../../components/searchbar/searchbar";
import Table from "../../components/table/table";
import { UserData } from "./userData";
import { Overlay } from "../../components/overlay";
import { UserForm } from "../../components/users/userForm";

const Users = () => {
    const [showModal, setShowModal] = useState(false);
    const [userData, setUserData] = useState<any>();

    const tableHeaderUserData = [
        "id",
        "First Name",
        "Last Name",
        "User Name",
        "Email",
        "Role",
    ];

    const handleEditUser = (item: any) => {
        setShowModal(true);
        setUserData(item);
    };

    const onCreateUserClick = () => {
        setShowModal(true);
        setUserData({});
    };

    const updateUserData = () => {
        // fetch and update userData here
    };

    return (
        <div>
            <div className="flex flex-row-reverse">
                <button
                    onClick={onCreateUserClick}
                    className="bg-indigo-600 text-white text-l font-semibold py-4 px-14 rounded mt-8 mx-16 hover:bg-indigo-800 ">
                    New User
                </button>
            </div>

            <hr className="h-px my-6 bg-gray-200 border-0" />

            {/* <Searchbar search setSearch /> */}
            <div className="mx-5 my-5">
                <Table
                    data={UserData}
                    showIcon={false}
                    tableHeader={tableHeaderUserData}
                    rowOnClick={handleEditUser}
                    updateData={updateUserData}
                />
            </div>
            {showModal && <Overlay onClick={() => setShowModal(false)} />}
            {showModal && <UserForm data={userData} />}
        </div>
    );
};

export default Users;
