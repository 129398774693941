import React from "react";
import { FaLongArrowAltLeft } from "@react-icons/all-files/fa/FaLongArrowAltLeft";
import { DataStatus, TransactionType } from "../../types";
import Button from "../button";

interface PropTypes {
    onClickClose: () => void;
    transactionData: TransactionType;
    onRestartClick: () => void;
}

const TransactionDetails = (props: PropTypes) => {
    const date = new Date(props.transactionData.date).toString().split("G")[0];
    return (
        <div className="pt-4  w-full ">
            <button
                onClick={props.onClickClose}
                className="mx-10 text-gray-400"
            >
                <FaLongArrowAltLeft size={30} />
            </button>
            <div className=" flex gap-16 justify-between w-full h-full pl-20 pr-5">
                <div className="flex flex-col gap-2 w-full">
                    <div className="flex flex-col gap-1 py-1 border-b border-b-gray-300 ">
                        <h3 className="font-semibold text-xl">
                            Transaction Details
                        </h3>
                        <p className="text-gray-500 text-xs">{date}</p>
                    </div>
                    <div className="flex w-full justify-between py-6 border-b border-b-gray-300 mt-4">
                        <h3 className="text-lg">Status:</h3>
                        <p
                            className={` ${props.transactionData.status ===
                                DataStatus.failed_trans
                                ? "text-red-500"
                                : ""
                                }  ${props.transactionData.status ===
                                    DataStatus.completed_trans
                                    ? "text-green-400"
                                    : ""
                                } ${props.transactionData.status ===
                                    DataStatus.pending_trans
                                    ? "text-yellow-500"
                                    : ""
                                }`}
                        >
                            {props.transactionData.status.toUpperCase()}
                        </p>
                    </div>
                    <div className="flex w-full justify-between py-6 border-b border-b-gray-300 mt-4">
                        <h3 className="text-lg">Sender Number:</h3>
                        <p className="">
                            {props.transactionData.sender_number}
                        </p>
                    </div>{" "}
                    <div className="flex w-full justify-between py-6 border-b border-b-gray-300 mt-4">
                        <h3 className="text-lg">Amount:</h3>
                        <p className="">XAF {props.transactionData.amount}</p>
                    </div>{" "}
                    <div className="flex w-full justify-between py-6 border-b border-b-gray-300 mt-4">
                        <h3 className="text-lg">Withdrawal Fee:</h3>
                        <p className="">XAF 0</p>
                    </div>{" "}
                    <div className="flex w-full justify-between py-6 border-b border-b-gray-300 mt-4">
                        <h3 className="text-lg">Transaction ID:</h3>
                        <p className="">{props.transactionData.id}</p>
                    </div>{" "}
                    <div className="flex w-full justify-between gap-40 py-6 border-b border-b-gray-300 mt-4">
                        <h3 className="text-lg">Message:</h3>
                        <p className="text-end flex justify-end max-w-[400px] items-end text-wrap ">
                            Some longer text here, yep yep yep kdjhfjd jhdk
                            jknfkdfjkdfkjdkfbdnbfdnbfmdnfdmbfdbfddffd
                            dlkjfdknfkdbfdbfbdjfbdfbdjbfhdf
                        </p>
                    </div>
                </div>

                <div className="w-[60%] h-auto bg-[#F6F4FF] flex flex-col mt-12 py-7 px-5 relative  rounded-lg">
                    <h3 className="font-semibold text-xl">Recipient</h3>
                    <div className="border-b border-b-gray-300 flex flex-col gap-7 pb-12">
                        <div className="flex w-full justify-between  mt-11">
                            <h3 className="text-lg">Status:</h3>
                            <p
                                className={` ${props.transactionData.status ===
                                    DataStatus.failed_trans
                                    ? "text-[#F47B7B]"
                                    : ""
                                    }  ${props.transactionData.status ===
                                        DataStatus.completed_trans
                                        ? "text-[#47EE76]"
                                        : ""
                                    } ${props.transactionData.status ===
                                        DataStatus.pending_trans
                                        ? "text-[#F5D00C]"
                                        : ""
                                    }`}
                            >
                                {props.transactionData.status.toUpperCase()}
                            </p>
                        </div>
                        <div className="flex w-full justify-between">
                            <h3 className="text-lg">Recipient Number:</h3>
                            <p className="">
                                {props.transactionData.receiver_number}
                            </p>
                        </div>{" "}
                    </div>
                    <Button
                        disable={props.transactionData.status === DataStatus.completed_trans || props.transactionData.status === DataStatus.not_started_trans}
                        title="Restart"
                        onClick={props.onRestartClick}
                        styles="bg-[#4F27F3] absolute bottom-0 right-5 mb-8"
                    />
                </div>
            </div>
        </div>
    );
};

export default TransactionDetails;
