import TransactionList from "../../components/transactionList/transactionList";

const Transactions = () => {
    return (
        <div className="py-3 relative">
            <TransactionList />
        </div>
    );
};

export default Transactions;
