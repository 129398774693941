import { USER_FIRSTNAME } from "../../constants/storageKeys";
import useLogoutUser from "../../utils/logout";


const Header = () => {
    const data = localStorage.getItem(USER_FIRSTNAME)
    const firstname = !!data ? JSON.parse(data): '';
    const { logout } = useLogoutUser() 
   
    return (
        <div className="py-4 drop-shadow-md bg-white px-8">
            <div className="container mx-auto flex justify-between items-center">
                <h1 className="text-3xl font-semibold">Admin Panel</h1>
                <h2 className="text-xl font-semibold ml-auto mr-16">
                    {firstname}
                </h2>
                <p onClick={logout} className="font-semibold text-xl cursor-pointer">Logout</p>
            </div>
        </div>
    );
};

export default Header;