import ServicesCard from "../../components/dashboard/servicesCard";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import transationPNG from "../../assets/images/dashboard/tranaction.png";
import userPNG from "../../assets/images/dashboard/usericon.png";
import emailPNG from "../../assets/images/dashboard/emailsmedia.png";
import StatsSection from "../../components/dashboard/statsSection";
import { Role, RoleTypes } from "../../types";
import { useAppSelector } from "../../store/hooks";
import getUserRole from "../../modules/dashboard/getUserRole";
import { USER_ROLE_ID } from "../../constants/storageKeys";

let services = [
    {
        title: "Transactions",
        image: transationPNG,
        path: ROUTES.TRANSACTIONS,
        role: Role.CUSTOMER_SERVICE,
    },
    { title: "Users", image: userPNG, path: ROUTES.USERS, role: Role.ADMIN },
    {
        title: "Emails",
        image: emailPNG,
        path: ROUTES.EMAILS,
        role: Role.CUSTOMER_SERVICE,
    },
];

const Dashboard = () => {
    const [currentUserRole, setCurrentUserRole] = useState<RoleTypes>();

    const roles = useAppSelector((state) => state.role.role);
    const data = localStorage.getItem(USER_ROLE_ID);
    const role_id = !!data ? JSON.parse(data): '';

    useEffect(
        () => {
            if (roles) {
                const role = getUserRole(role_id, roles);
                setCurrentUserRole(role);
            }
        },
        //ESLINT DISABLED BELOW
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [roles, role_id]);

    const navigate = useNavigate();

    return (
        <div className="flex flex-col px-12 py-8 ">
            <div className="services flex gap-24 mt-6 mb-10">
                {services.map((service, i) => (
                    <ServicesCard
                        key={i}
                        title={service.title}
                        onClick={() => navigate(service.path)}
                        image={service.image}
                        hidden={
                            currentUserRole?.name !== Role.ADMIN &&
                            service.role === Role.ADMIN
                        }
                    />
                ))}
            </div>
            <h4 className="text-xl font-semibold">Statistics</h4>

            <StatsSection role={currentUserRole?.name} />
        </div>
    );
};

export default Dashboard;
